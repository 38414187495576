window.getElementAbsoluteOffset = function(el) {
  var ret = el.offsetTop;
  while (el.offsetParent) {
    el = el.offsetParent;
    ret += el.offsetTop;
  }

  return ret;
};

window.toCamel = function(o) {
  var exclusions = ["sections"];
  var newO, origKey, newKey, value;

  if (o instanceof Array) {
    return o.map(function(value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        value = o[origKey];

        if (exclusions.indexOf(origKey) !== -1) {
          newKey = origKey;
        } else {
          newKey = _.camelCase(origKey);
          newKey = origKey.substr(0, 1) + newKey.substr(1, newKey.length - 1);

          if (
            value instanceof Array ||
            (value !== null && value.constructor === Object)
          ) {
            value = toCamel(value);
          }
        }

        newO[newKey] = value;
      }
    }
  }
  return newO;
};

window.CONSTS = {
  WAIT: 25,
  INDENT: 50,
  SPECIAL_CHARS: {
    ZERO_WIDTH_SPACE: '​',
    ZERO_WIDTH_NO_BREAKING_SPACE: '﻿',
  },
  STEP_SIZE : 36,
  ADMIN_COLOR: '#009c21',
};

window.ENUMS = {};

window.ENUMS.MOUSE = {
  NO_BUTTON: 0,
  LEFT_MOUSE_BUTTON: 1,
  WHEEL_BUTTON_OR_MIDDLE_BUTTON: 2,
  RIGHT_MOUSE_BUTTON: 3,
};



window.ENUMS.KEYS = {
  SPACE_BAR: 32,
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_A: 65,
  KEY_B: 66,
  KEY_C: 67,
  KEY_D: 68,
  KEY_E: 69,
  KEY_F: 70,
  KEY_G: 71,
  KEY_H: 72,
  KEY_I: 73,
  KEY_J: 74,
  KEY_K: 75,
  KEY_L: 76,
  KEY_M: 77,
  KEY_N: 78,
  KEY_O: 79,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_R: 82,
  KEY_S: 83,
  KEY_T: 84,
  KEY_U: 85,
  KEY_V: 86,
  KEY_W: 87,
  KEY_X: 88,
  KEY_Y: 89,
  KEY_Z: 90,
  LEFT_META: 91,
  RIGHT_META: 92,
  SELECT: 93,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  MULTIPLY: 106,
  ADD: 107,
  SUBTRACT: 109,
  DECIMAL: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  SEMICOLON: 186,
  EQUALS: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  GRAVE_ACCENT: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  SINGLE_QUOTE: 222,
};

window.ENUMS.KEY_CODES = Object.values(window.ENUMS.KEYS);

(function() {
  (function($) {
    'use strict';
    $.fn.isAfter = function(sel) {
      const all = this.parents().prevAll();
      const children = all.children();

      return all.filter(sel).length !== 0 || children.filter(sel).length !== 0;
    };

    $.fn.isBefore = function(sel) {
      const all = this.parents().nextAll();
      const children = all.children();

      return all.filter(sel).length !== 0 || children.filter(sel).length !== 0;
    };
  })(jQuery);
}.call(this));

var app = angular
  .module('LeasePilot', [
    'rails',
    'ngMaterial',
    'ngMessages',
    'templates',
    'ngFileUpload',
    'ngCookies',
    'ngSanitize',
    'ngAnimate',
    'ui.bootstrap',
    'ngAria',
    'dndLists',
    'angular-bind-html-compile',
    'ngRaven',
  ])
  .config([
    '$sceDelegateProvider',
    '$compileProvider',
    function($sceDelegateProvider, $compileProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'https://lease-pilot-production.s3.amazonaws.com/uploads/**',
        'https://lease-pilot-acceptance.s3.amazonaws.com/uploads/**',
        'https://leasep.s3.amazonaws.com/uploads/**',
        window._assets_url + '/**',
      ]);

      if (window.isDownload) {
        $compileProvider.debugInfoEnabled(false);
      }

      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file):/);
    },
  ]);

app.run([
  '$window',
  '$rootScope',
  async ($window, $rootScope) => {
    $rootScope.$window = $window;

    $rootScope.getContext = function() {
      let context = 'normal';

      return context;
    };

    // Makes sure we're not calling $apply while in digest cycle
    // https://coderwall.com/p/ngisma/safe-apply-in-angular-js
    $rootScope.safeApply = function(fn) {
      var phase = this.$root.$$phase;
      if (phase === '$apply' || phase === '$digest') {
        if (fn && typeof fn === 'function') {
          fn();
        }
      } else {
        this.$apply(fn);
      }
    };

    $rootScope.constructor.prototype.safeApply = $rootScope.safeApply;
  },
]);
